//Variables a utilizar
const verClaveElemento = document.querySelector("#verclave");
const claveElemento = document.querySelector("#clave");

verClaveElemento.addEventListener("click", ()=>{
    
    if(verClaveElemento.checked == true){
        claveElemento.type = "text";
    }else{
        claveElemento.type = "password";
    }

})